<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Orders Module</h4>
        </v-col>
      </v-layout>

      <v-layout wrap class="mt-3">
        <v-col cols="12" md="3">
          <v-layout class="boxStat" style="border: 1px solid #ffd809;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">All Orders</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6"> 
                  <span class="text-h5 ml-5"><b>{{allOrders.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small @click="getAllorders">
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="boxStat" style="border: 1px solid #72BD61;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Opened Orders</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{OpenOrders.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small @click="getOpenedorders">
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="boxStat" style="border: 1px solid #2776ED;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Cancelled</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{CancelledOrders.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small @click="getCanceledorders">
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="boxStat" style="border: 1px solid #61BDBD;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Completed</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{CompletedOrders.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small @click="getCompletedorders">
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg">
            <v-col cols="12">
              <v-layout wrap>
                <h5>All Orders</h5>
              </v-layout>

              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Order Id
                          </th>
                          <th class="text-left">
                            Tracking Number
                          </th>
                          <th class="text-left">
                            Date
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(order,index) in Orders" :key="index">
                          <td>{{order.orderId}}</td>
                          <td>{{order.trackingNumber}}</td>
                          <td>{{order.createdAt}}</td>
                          <td>
                            <v-layout v-if="order.status == 'deleted'">
                            <span style="color:#BD2121">Order {{ order.status }}</span>
                            </v-layout>
                            <v-layout v-else-if="order.status == 'created'">
                              <span style="color:red">Order {{ order.status }}</span>
                            </v-layout>
                            <v-layout v-else-if="order.status == 'awaiting-delivery'">
                              <span style="color:black">Order {{ order.status }}</span>
                            </v-layout>
                            <v-layout v-else-if="order.status == 'approved'">
                              <span style="color:#BD2121">Order Dispatched</span>
                            </v-layout>
                            <v-layout v-else>
                              <span style="color:green">Order {{ order.status }}</span>
                            </v-layout>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
export default {
  name: "AdminOrdersModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      systemSetting: {
        systemCurrency: "₦",
      },
      allOrders:[],
      Orders:[],
      OpenOrders:[],
      CompletedOrders: [],
      CancelledOrders:[],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
        .then((response) => {
         this.allOrders = response.data.data;
         this.Orders = response.data.data;
         response.data.data.forEach(element => {
            if(element.status == 'created'){
              this.OpenOrders.push(element);
            }

            if(element.status == 'completed'){
              this.CompletedOrders.push(element);
            }

            if(element.status == 'cancelled'){
              this.CancelledOrders.push(element);
            }
         });
        });
    },

    getAllorders() {
      this.Orders = [];
      this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
        .then((response) => {
         this.Orders = response.data.data;
        });
    },

    getOpenedorders() {
      this.Orders = [];
      this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
        .then((response) => {
         response.data.data.forEach(element => {
            if(element.status == 'created'){
              this.Orders.push(element);
            }
         });
        });
    },

    getCanceledorders() {
      this.Orders = [];
      this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
        .then((response) => {
         response.data.data.forEach(element => {
            if(element.status == 'cancelled'){
              this.Orders.push(element);
            }
         });
        });
    },

    getCompletedorders() {
      this.Orders = [];
      this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
        .then((response) => {
         response.data.data.forEach(element => {
             if(element.status == 'completed'){
              this.Orders.push(element);
            }
         });
        });
    },
  }
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}
</style>
